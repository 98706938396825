<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">
        Raport produse
      </div>

      <div
        class="col2_subHead"
        style="margin-top: 1.2rem; display: flex; justify-content: flex-end;"
      >
        <div class="Button1 Btn_center" @click="generateExcel">
          <div class="icon">
            <i class="fas fa-download"></i>
          </div>
          <span class="Btn_content"> Descarcă </span>
        </div>
      </div>
    </div>
    <template>
      <template v-if="PERMISIONS.report">
        <table-parent
          :reloadCount="reloadTable"
          :tableHead="tableHead"
          :prepareFn="prepareData"
          :apiModule="tableModule"
          apiModuleName="GET_BUDGET_REPORTS"
          @totalRecords="(x) => (totalRecords = x)"
        />
      </template>
      <template v-else>
        <h3 style="display: flex; flex-direction: column; align-items: center">
          <span style="font-size: 4rem">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          Acces interzis
        </h3>
      </template>
    </template>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";

import PermisionsMixin from "../mixins/bugetarePermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import { GET_BUDGET_REPORTS, DOWNLOAD_BUDGET_REPORTS } from "../api.js";
import TableParent from "@/components/TableParent";

export default {
  mixins: [PermisionsMixin, updateRoleStatus],
  components: {
    TableParent,
  },
  data() {
    return {
      tableHead: [
        {
          title: "Sursa de finanțare",
          queryKey: "financingSource.sourceName",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Clasificația funcțională",
          queryKey: "budgetFunctional",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Denumire clasificație",
          queryKey: "budgetEconomic.name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Articol",
          queryKey: "budgetEconomic.titlu",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Credite aprobate",
          queryKey: "needReferenceSum",
          bottomHead: {
            quikFiltre: true,
          },
        },

        {
          title: "Credite angajate",
          queryKey: "acqSum",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Credite ramase",
          queryKey: "valueDifference",
          bottomHead: {
            quikFiltre: true,
          },
        },
      ],
      tableModule: GET_BUDGET_REPORTS,
      totalRecords: 0,
      reloadTable: 0,
    };
  },
  methods: {
    reload() {
      this.reloadTable++;
    },
    prepareData(row) {
      const preparation = [
        row.sourceName || "-",
        row.budgetFunctional || "-",
        row.budgetEconomic || "-",
        row.budgetEconomicCode || "-",
        this.prepareLocaleNumber(row.needReferenceSum) || "-",
        this.prepareLocaleNumber(row.acqSum) || "-",
        this.prepareLocaleNumber(row.valueDifference) || "-",
      ];

      return preparation;
    },
    generateExcel() {
      DOWNLOAD_BUDGET_REPORTS()
        .then((res) => {
          var blob = new Blob([res], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, `${uuidv4()}.xlsx`);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    if (!this.PERMISIONS.report) {
      return;
    }
    this.updateRoleStatus();
  },
};
</script>
<style></style>
